import {
  Box,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import React, { FC, useCallback, useState } from 'react';

import { ALLOWED_CRYPTOCURRENCIES, CRYPTOCURRENCY_ICONS } from '@variable';
import { CryptoCurrency } from '@enum/cryptocurrency.enum';
import { updateApp } from '@action/app.action';
import App from '@interface/app.interface';
import { useAppDispatch } from '@hook/hooks.hook';
import LoadingButton from '@component/LoadingButton';

const arraysAreEqual = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) return false;
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) return false;
  }

  return true;
};

const AppCurrencies: FC<{ appCurrencies: CryptoCurrency[]; id: string }> = ({
  id,
  appCurrencies: initialAppCurrencies,
}) => {
  const [appCurrencies, setAppCurrencies] =
    useState<CryptoCurrency[]>(initialAppCurrencies);
  const isUpdatingApp = false;
  const dispatch = useAppDispatch();

  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      await dispatch(
        updateApp({
          id,
          appCurrencies,
        } as App),
      );
    },
    [appCurrencies, dispatch, id],
  );

  const handleToggleAppCurrencies = (
    checked: boolean,
    currency: CryptoCurrency,
  ) => {
    setAppCurrencies((currencies) => {
      if (currencies.length === 1 && !checked) return currencies;
      if (checked) return [...currencies, currency];
      return currencies.filter((c) => c !== currency);
    });
  };

  return (
    <>
      <Box>
        <Stack>
          <Typography variant="h6" fontWeight="bold" color="primary">
            App Currencies
          </Typography>
          <Typography variant="subtitle1">
            Control the cryptocurrencies that your app will accept
          </Typography>
        </Stack>

        <Stack spacing={2} mt={2} direction="row" gap={4}>
          {ALLOWED_CRYPTOCURRENCIES.map((currency) => (
            <FormControlLabel
              control={
                <Switch
                  checked={appCurrencies.includes(currency)}
                  onChange={(_, checked) =>
                    handleToggleAppCurrencies(checked, currency)
                  }
                />
              }
              label={
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  gap={1}
                  direction="row"
                >
                  <img
                    src={CRYPTOCURRENCY_ICONS[currency]}
                    alt={`${currency} icon`}
                    height={24}
                    width={24}
                  />
                  {currency}
                </Stack>
              }
              key={currency}
            />
          ))}
        </Stack>
      </Box>
      <LoadingButton
        isLoading={isUpdatingApp}
        variant="contained"
        onClick={onSubmit}
        disabled={arraysAreEqual(appCurrencies, initialAppCurrencies)}
        sx={{ width: 'fit-content' }}
      >
        Update Currencies
      </LoadingButton>
    </>
  );
};

export default AppCurrencies;
