import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_URL, SLICE_NAME } from '@variable';
import App from '@interface/app.interface';
import { AppTransaction } from '@interface/appTransaction.interface';

const APP_URL = `${API_URL}/app`;

export const fetchApps = createAsyncThunk(
  `${SLICE_NAME}/fetchApps`,
  async (): Promise<App[]> => {
    const result = await axios(APP_URL);
    return result.data;
  },
);

export const fetchAppTransactions = createAsyncThunk(
  `${SLICE_NAME}/fetchAppTransactions`,
  async ({
    appId,
    currency,
    page = 1,
    limit = 20,
  }: {
    appId: string;
    currency: string;
    page?: number;
    limit?: number;
  }): Promise<AppTransaction> => {
    const result = await axios(
      `${APP_URL}/${appId}/transactions?page=${page}&limit=${limit}&currency=${currency}`,
    );
    return result.data;
  },
);

export const createApp = createAsyncThunk(
  `${SLICE_NAME}/createApp`,
  async (name: string): Promise<App> => {
    const result = await axios.post(APP_URL, { name });
    return result.data;
  },
);

export const updateApp = createAsyncThunk(
  `${SLICE_NAME}/updateApp`,
  async (app: App): Promise<App> => {
    const result = await axios.put(APP_URL, app);
    return result.data;
  },
);

export const toggleAppMode = createAsyncThunk(
  `${SLICE_NAME}/toggleAppMode`,
  async (id: string): Promise<App> => {
    const result = await axios.put(`${APP_URL}/${id}/change-mode`);
    return result.data;
  },
);

export const deleteApp = createAsyncThunk(
  `${SLICE_NAME}/deleteApp`,
  async (id: string): Promise<boolean> => {
    const result = await axios.delete(`${APP_URL}/${id}`);
    return result.data.deleted;
  },
);

export const suspendApp = createAsyncThunk(
  `${SLICE_NAME}/suspendApp`,
  async (id: string): Promise<App> => {
    const result = await axios.put(`${API_URL}/admin/apps/${id}/suspend`);
    return result.data;
  },
);

export const activateApp = createAsyncThunk(
  `${SLICE_NAME}/activateApp`,
  async (id: string): Promise<App> => {
    const result = await axios.put(`${API_URL}/admin/apps/${id}/activate`);
    return result.data;
  },
);

export const requestUserReport = createAsyncThunk(
  `${SLICE_NAME}/requestUserReport`,
  async (email: string): Promise<string> => {
    const result = await axios.post(`${API_URL}/admin/report/users`, { email });
    return result.data;
  },
);
