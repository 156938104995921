import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import { purple } from '@mui/material/colors';
import { AddCircle } from '@mui/icons-material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';

import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import {
  drainStorage,
  drainWallets,
  fetchWallets,
} from '@action/wallet.action';
import { selectWallets } from '@selector/wallet.selector';
import AddressDialog from '@page/Wallets/AddressDialog';
import LoadingButton from '@component/LoadingButton';
import { CryptoCurrency } from '@enum/cryptocurrency.enum';
import ManageWallet from '@page/Wallets/ManageWallet';
import { selectUserRole } from '@selector/permission.selector';
import UserRole from '@enum/userRole.enum';

const Wallets: React.FC = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(selectWallets);
  const [openAddWalletDialog, setOpenAddWalletDialog] = useState(false);
  const [isDrainingWallets, setIsDrainingWallets] = useState(false);
  const [isDrainingStorage, setIsDrainingStorage] = useState(false);
  const [addressDialogId, setAddressDialogId] = useState<null | string>(null);
  const userRole = useAppSelector(selectUserRole);
  const isSuperAdmin = userRole === UserRole.SUPER_ADMIN;

  useEffect(() => {
    dispatch(fetchWallets());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOpenWalletDialog = (id: string) => {
    setAddressDialogId(id);
  };

  const onCloseWalletDialog = () => {
    setAddressDialogId(null);
  };

  const onClick = () => {
    setOpenAddWalletDialog(true);
  };

  const onDrainWallets = useCallback(async () => {
    if (!isSuperAdmin) return;
    setIsDrainingWallets(true);
    await dispatch(drainWallets());
    setIsDrainingWallets(false);
  }, [isSuperAdmin, dispatch]);

  const onDrainUsdtStorage = useCallback(async () => {
    if (!isSuperAdmin) return;

    setIsDrainingStorage(true);
    await dispatch(drainStorage({ currency: CryptoCurrency.USDT }));
    setIsDrainingStorage(false);
  }, [isSuperAdmin, dispatch]);

  const onDrainUsdcStorage = useCallback(async () => {
    if (!isSuperAdmin) return;

    setIsDrainingStorage(true);
    await dispatch(drainStorage({ currency: CryptoCurrency.USDC }));
    setIsDrainingStorage(false);
  }, [isSuperAdmin, dispatch]);

  return (
    <>
      <Toolbar sx={{ justifyContent: 'space-between', mb: 1 }}>
        <Typography variant="h4">Wallets ({data?.length || 0})</Typography>

        {isSuperAdmin && (
          <Stack direction="row" gap={1}>
            <LoadingButton
              variant="contained"
              onClick={onDrainUsdtStorage}
              isLoading={isDrainingStorage}
            >
              Drain USDT Storage
            </LoadingButton>

            <LoadingButton
              variant="contained"
              onClick={onDrainUsdcStorage}
              isLoading={isDrainingStorage}
            >
              Drain USDC Storage
            </LoadingButton>

            <LoadingButton
              onClick={onDrainWallets}
              isLoading={isDrainingWallets}
              variant="contained"
            >
              Drain App Wallets
            </LoadingButton>
            <IconButton onClick={onClick} color="primary">
              <AddCircle />
            </IconButton>
          </Stack>
        )}
      </Toolbar>

      <Paper sx={{ p: 4, mb: 4 }} elevation={3}>
        {data?.length && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Address</TableCell>
                  <TableCell>USDT</TableCell>
                  <TableCell>USDC</TableCell>
                  <TableCell>TRX</TableCell>
                  <TableCell>Energy</TableCell>
                  <TableCell>Bandwidth</TableCell>
                  <TableCell>Frozen</TableCell>
                  <TableCell>Activated</TableCell>
                  <TableCell>Locked</TableCell>
                  <TableCell>Assigned</TableCell>
                  <TableCell>Last Update</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map(
                  ({
                    id,
                    updatedAt,
                    address,
                    appTransactionId,
                    usdtBalance,
                    usdcBalance,
                    trxBalance,
                    energy,
                    bandwidth,
                    totalFrozenAsset,
                    isAccountActivated,
                    isLocked,
                  }) => {
                    return (
                      <TableRow
                        key={id}
                        onClick={() => onOpenWalletDialog(id as string)}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': { bgcolor: purple[100] },
                        }}
                      >
                        <TableCell>{address}</TableCell>
                        <TableCell>{usdtBalance}</TableCell>
                        <TableCell>{usdcBalance}</TableCell>
                        <TableCell>{trxBalance}</TableCell>
                        <TableCell>{energy}</TableCell>
                        <TableCell>{bandwidth}</TableCell>
                        <TableCell>{totalFrozenAsset}</TableCell>
                        <TableCell>
                          {isAccountActivated ? '✅' : '❌'}
                        </TableCell>
                        <TableCell>
                          {isLocked ? (
                            <LockIcon color="error" />
                          ) : (
                            <LockOpenIcon color="secondary" />
                          )}
                        </TableCell>
                        <TableCell>{appTransactionId ? '✅' : '❌'}</TableCell>
                        <TableCell>{dayjs(updatedAt).fromNow()}</TableCell>
                      </TableRow>
                    );
                  },
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      <AddressDialog
        open={openAddWalletDialog}
        onClose={() => {
          setOpenAddWalletDialog(false);
        }}
      />
      {Boolean(addressDialogId) && (
        <ManageWallet
          isSuperAdmin={isSuperAdmin}
          id={addressDialogId as string}
          onClose={onCloseWalletDialog}
        />
      )}
    </>
  );
};

export default Wallets;
