import React, { useCallback, useState } from 'react';
import { Stack, TextField, Typography } from '@mui/material';

import LoadingButton from '@component/LoadingButton';
import { requestUserReport } from '@action/app.action';
import { useAppDispatch } from '@hook/hooks.hook';

const Overview: React.FC = () => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const disableForThirtySeconds = () => {
    setIsDisabled(true);
    setCountdown(30000);
    setTimeout(() => {
      const interval = setInterval(() => {
        setCountdown((value) => {
          const result = value - 1000;

          if (result > 0) return result;
          clearInterval(interval);
          setIsDisabled(false);
          return 0;
        });
      }, 1000);
    }, 300);
  };

  const onSubmit = useCallback(async () => {
    setIsLoading(true);
    try {
      const res: any = await dispatch(requestUserReport(email.trim()));
      if (!res.error) disableForThirtySeconds();
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, email]);

  return (
    <Stack maxWidth={400} gap={2}>
      <Typography>
        Enter your email address below to receive a CSV report of users and
        their KYC status
      </Typography>
      <TextField
        required
        label="Email"
        value={email}
        type="email"
        onChange={(e) => setEmail(e.target.value)}
      />
      <LoadingButton
        disabled={isDisabled}
        isLoading={isLoading}
        variant="contained"
        onClick={onSubmit}
      >
        {countdown > 0 ? `Wait for ${countdown / 1000} secs` : 'Submit'}
      </LoadingButton>
    </Stack>
  );
};

export default Overview;
