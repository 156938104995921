import React from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import AppWalletInterface from '@interface/appWallet.interface';
import { CryptoCurrency } from '@enum/cryptocurrency.enum';
import { CRYPTOCURRENCY_ICONS } from '@variable';

interface WalletBalanceProps {
  wallets: AppWalletInterface[];
  onWithdraw?: (currency: CryptoCurrency) => void;
}

const WalletBalance: React.FC<WalletBalanceProps> = ({
  wallets,
  onWithdraw,
}) => {
  const navigate = useNavigate();
  const canWithdrawFromWallet = true;

  return (
    <Grid container spacing={2}>
      {wallets.map(({ currency, balance }, index) => {
        return (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent sx={{ position: 'relative' }}>
                <img
                  src={CRYPTOCURRENCY_ICONS[currency]}
                  alt={`${currency} icon`}
                  height={35}
                  width={35}
                  style={{ position: 'absolute', top: 16, right: 16 }}
                />

                <Stack>
                  <Typography variant="subtitle2">Balance</Typography>
                  <Typography variant="h6">
                    {balance} {currency}
                  </Typography>
                </Stack>

                <CardActions sx={{ p: 0, justifyContent: 'flex-end' }}>
                  {canWithdrawFromWallet && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onWithdraw?.(currency)}
                      sx={{ mt: 2, flexGrow: 1 }}
                      size="small"
                    >
                      Withdraw
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{
                      mt: 2,
                      flexGrow: canWithdrawFromWallet ? 1 : undefined,
                    }}
                    onClick={() => navigate(`history/${currency}`)}
                    size="small"
                  >
                    History
                  </Button>
                </CardActions>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default WalletBalance;
