import { Box, Checkbox, Paper, Stack, TextField } from '@mui/material';
import { useCallback, useState } from 'react';

import LoadingButton from '@component/LoadingButton';
import { useAppDispatch } from '@hook/hooks.hook';
import { seedUserTransactions } from '@action/user.action';

const SeedUser = () => {
  const [userId, setUserId] = useState('');
  const [clearPrevData, setClearPrevData] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useAppDispatch();

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    try {
      await dispatch(seedUserTransactions({ userId, clearPrevData }));
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }, [dispatch, userId, clearPrevData]);

  return (
    <Box>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Stack width={400} alignItems="flex-start" gap={1}>
          <TextField
            value={userId}
            fullWidth={false}
            onChange={({ target: { value: id } }) => setUserId(id)}
          />

          <Checkbox
            checked={clearPrevData}
            onChange={({ target: { checked } }) => setClearPrevData(checked)}
          />

          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            isLoading={isSubmitting}
          >
            submit
          </LoadingButton>
        </Stack>
      </Paper>
    </Box>
  );
};

export default SeedUser;
