import React from 'react';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { Error } from '@mui/icons-material';

import { DirectorCardProps } from '@interface/company.interface';

const FIELDS = [
  'fullName',
  'dateOfBirth',
  'nationality',
  'phoneNumber',
  'countryOfResidence',
  'email',
  'idUpload',
  'state',
  'street',
  'city',
  'utilityBillUpload',
];

const DirectorCard: React.FC<DirectorCardProps> = ({
  onViewDirector,
  director,
}) => {
  const incompleteInfo = ((): boolean =>
    FIELDS.some((field) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return !director[field];
    }))();

  return (
    <>
      <Card
        variant="outlined"
        style={{ marginBottom: 16, height: '100%' }}
        sx={{ boxShadow: 1 }}
      >
        <CardContent sx={{ position: 'relative', height: '100%' }}>
          <Typography variant="h6">{director.fullName}</Typography>

          {!incompleteInfo && Boolean(director.email) && (
            <Typography variant="body2">Email: {director.email} </Typography>
          )}

          {!incompleteInfo && director.phoneNumber && (
            <Typography variant="body2">
              Phone: +234 {director.phoneNumber}
            </Typography>
          )}

          {incompleteInfo && (
            <Box
              display="flex"
              justifyContent="flex-end"
              sx={{
                position: 'absolute',
                top: 16,
                right: 16,
              }}
            >
              <Tooltip title="Incomplete">
                <Error color="error" />
              </Tooltip>
            </Box>
          )}

          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 16,
            }}
          >
            <Tooltip title="View">
              <IconButton onClick={() => onViewDirector(director)}>
                <VisibilityRoundedIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default DirectorCard;
