import { Field, Form, Formik } from 'formik';
import {
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import * as Yup from 'yup';
import isEqual from 'lodash.isequal';

import RoleIcon from '@component/RoleIcon';
import UserRole from '@enum/userRole.enum';
import { PHONE_NUMBER_REGEX, ROLE_MAP } from '@variable';
import LoadingButton from '@component/LoadingButton';
import User from '@interface/user.interface';
import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import { selectUser } from '@selector/auth.selector';
import { updateUser } from '@action/user.action';
import PhoneNumberField from '@component/PhoneNumberField';

const validationSchema = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  phoneNumber: Yup.string()
    .matches(PHONE_NUMBER_REGEX, 'Invalid Nigerian phone number')
    .required('Phone Number is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
});

const BasicInfoSection = () => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const initialValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    phoneNumber: user?.phoneNumber || '',
    email: user?.email || '',
  } as User;

  const handleSubmit = async (values: User) => {
    await dispatch(updateUser(values));
  };

  return (
    <Paper elevation={3} sx={{ p: 4 }}>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ errors, touched, isSubmitting, values, isValid }) => (
          <Form>
            <Box>
              <Stack
                direction="row"
                gap={1}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography variant="h6" fontWeight="bold">
                  Personal information
                </Typography>
                <RoleIcon role={user?.role as UserRole} color="primary" />
                <Typography variant="caption" fontWeight="bold" color="primary">
                  {ROLE_MAP[user?.role as UserRole]}
                </Typography>
              </Stack>
            </Box>

            <Divider variant="fullWidth" sx={{ mt: 2, mb: 4 }} />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Field
                  as={TextField}
                  name="firstName"
                  label="First Name"
                  fullWidth
                  variant="outlined"
                  error={touched.firstName && !!errors.firstName}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  as={TextField}
                  name="lastName"
                  label="Last Name"
                  fullWidth
                  variant="outlined"
                  error={touched.lastName && !!errors.lastName}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PhoneNumberField
                  as={TextField}
                  name="phoneNumber"
                  label="Phone Number"
                  fullWidth
                  variant="outlined"
                  error={touched.phoneNumber && !!errors.phoneNumber}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  as={TextField}
                  name="email"
                  label="Email"
                  fullWidth
                  variant="outlined"
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                />
              </Grid>
            </Grid>

            <Divider variant="fullWidth" sx={{ mt: 4, mb: 3 }} />

            <LoadingButton
              type="submit"
              color="primary"
              size="large"
              disabled={
                !isValid ||
                isEqual(initialValues, {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  phoneNumber: values.phoneNumber,
                  email: values.email,
                })
              }
              isLoading={isSubmitting}
              variant="contained"
            >
              Save
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};

export default BasicInfoSection;
