import React, { FC, useCallback, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import isEqual from 'lodash.isequal';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { useNavigate } from 'react-router-dom';

import TabPanel from '@component/AppDetail/TabPanel';
import { HTTPS_URL_REGEX } from '@variable';
import { useAppDispatch } from '@hook/hooks.hook';
import { deleteApp, updateApp } from '@action/app.action';
import App from '@interface/app.interface';
import ConfirmationDialog from '@component/ConfirmationDialog';

interface TaxAndSecurityProps {
  value: number;
  index: number;
  id: string;
  whitelistedUrls: string | string[];
  customerPaysTax: boolean;
  customerPaysFees: boolean;
}

const TaxAndSecurity: FC<TaxAndSecurityProps> = ({
  id,
  index,
  value,
  whitelistedUrls: initialWhitelistedUrls,
  customerPaysTax: initialCustomerPaysTax,
  customerPaysFees: initialCustomerPaysFees,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [whitelistedUrls, setWhitelistedUrls] = useState<string[]>(
    JSON.parse(initialWhitelistedUrls as string) || [],
  );
  const [newUrl, setNewUrl] = useState<string>('');
  const [customerPaysTax, setCustomerPaysTax] = useState<boolean>(
    initialCustomerPaysTax,
  );
  const [customerPaysFees, setCustomerPaysFees] = useState<boolean>(
    initialCustomerPaysFees,
  );
  const [openDeletionDialog, setOpenDeletionDialog] = useState<boolean>(false);

  const handleConfirmDeleteApp = async () => {
    const res = (await dispatch(deleteApp(id))) as any;
    if (!res.error) navigate('/dashboard/apps');
  };

  const confirmationDialogProps = {
    open: openDeletionDialog,
    title: 'Delete Application',
    subtitle: (
      <>
        <div>Are you sure you want to delete this application</div>
        <div>You can not undo this action once it&apos;s done</div>
      </>
    ),
    onClose: () => {
      setOpenDeletionDialog(false);
    },
    onConfirm: handleConfirmDeleteApp,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
  };

  const handleAddUrl = () => {
    if (
      newUrl &&
      HTTPS_URL_REGEX.test(newUrl) &&
      !whitelistedUrls.includes(newUrl)
    ) {
      setWhitelistedUrls([...whitelistedUrls, newUrl]);
      setNewUrl('');
    }
  };

  const handleRemoveUrl = (url: string) => {
    setWhitelistedUrls(whitelistedUrls.filter((item) => item !== url));
  };

  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      await dispatch(
        updateApp({
          id,
          whitelistedUrls,
          customerPaysTax,
          customerPaysFees,
        } as App),
      );
    },
    [customerPaysFees, customerPaysTax, dispatch, id, whitelistedUrls],
  );

  const disableUrlButton = isEqual(whitelistedUrls, initialWhitelistedUrls);

  const disableTaxButton =
    customerPaysTax === initialCustomerPaysTax &&
    customerPaysFees === initialCustomerPaysFees;

  return (
    <TabPanel value={value} index={index}>
      <form onSubmit={onSubmit}>
        <Stack spacing={3}>
          {/* Whitelisted URLs Section */}
          <Box>
            <Stack>
              <Typography variant="h6" fontWeight="bold" color="primary">
                Whitelisted URLs
              </Typography>
              <Typography variant="subtitle1">
                This URLs will be able to call your app
              </Typography>
            </Stack>
            <Stack spacing={2} mt={2}>
              {whitelistedUrls.map((url, index) => (
                <Box
                  key={index}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  maxWidth="400px"
                  width="100%"
                >
                  <Typography>{url}</Typography>
                  <Button
                    variant="text"
                    color="error"
                    onClick={() => handleRemoveUrl(url)}
                    startIcon={<RemoveCircleOutlineRoundedIcon />}
                  >
                    Remove
                  </Button>
                </Box>
              ))}
              <Box
                gap={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="center"
              >
                <TextField
                  name="url"
                  value={newUrl}
                  label="New URL"
                  sx={{ width: '100%', maxWidth: 400 }}
                  helperText={
                    newUrl && !HTTPS_URL_REGEX.test(newUrl)
                      ? 'Invalid url'
                      : 'Add the URLs that will be calling your app'
                  }
                  error={Boolean(newUrl && !HTTPS_URL_REGEX.test(newUrl))}
                  onChange={(e) => setNewUrl(e.target.value)}
                />
                <Stack direction="row" gap={4}>
                  <Button
                    variant="contained"
                    onClick={handleAddUrl}
                    disabled={
                      !HTTPS_URL_REGEX.test(newUrl) ||
                      whitelistedUrls.includes(newUrl)
                    }
                  >
                    Add URL
                  </Button>

                  {!disableUrlButton && (
                    <Button variant="contained" type="submit">
                      Update URL(s)
                    </Button>
                  )}
                </Stack>
              </Box>
            </Stack>
          </Box>
          <Divider />
          {/* Tax Options Section */}

          <Box>
            <Stack>
              <Typography variant="h6" fontWeight="bold" color="primary">
                Tax Options
              </Typography>
              <Typography variant="subtitle1">
                This is how we will calculate the fees for each transaction
              </Typography>
            </Stack>

            <Stack spacing={2} mt={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={customerPaysTax}
                    onChange={(e) => setCustomerPaysTax(e.target.checked)}
                  />
                }
                label={
                  <>
                    <Typography>Customer Pays Tax?</Typography>
                    <Alert color="warning">
                      {customerPaysTax
                        ? 'We will charge the customer an additional 7.5% for VAT on top of the total amount you charged'
                        : 'We will deduct the 7.5% VAT from the total amount you charged the customer'}
                    </Alert>
                  </>
                }
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={customerPaysFees}
                    onChange={(e) => setCustomerPaysFees(e.target.checked)}
                  />
                }
                label={
                  <>
                    <Typography>Customer Pays Fees?</Typography>
                    <Alert color="warning">
                      {customerPaysFees
                        ? 'We will charge the customer for the blockchain transaction fees'
                        : 'We will charge you for the blockchain transaction fees'}
                    </Alert>
                  </>
                }
              />
            </Stack>
          </Box>
          <Button
            variant="contained"
            type="submit"
            disabled={disableTaxButton}
            sx={{ width: 'fit-content' }}
          >
            Save Settings
          </Button>

          <Divider />

          <Box
            sx={{
              p: 4,
              borderRadius: 2,
              boxShadow: 3,
              backgroundColor: 'background.paper',
              maxWidth: '400px',
              mx: 'auto',
            }}
          >
            <Stack spacing={2}>
              <Typography variant="h6" fontWeight="bold" color="primary">
                Delete Application
              </Typography>

              <Typography variant="subtitle1" color="text.secondary">
                Deleting this application will remove all data associated with
                it. This action cannot be undone.
              </Typography>

              <Button
                sx={{
                  width: 'fit-content',
                  borderRadius: 2,
                  boxShadow: 2,
                  ':hover': {
                    boxShadow: 4,
                  },
                }}
                variant="contained"
                color="error"
                size="large"
                onClick={() => {
                  setOpenDeletionDialog(true);
                }}
              >
                Delete
              </Button>
            </Stack>
          </Box>
        </Stack>
      </form>

      {openDeletionDialog && (
        <ConfirmationDialog {...confirmationDialogProps} />
      )}
    </TabPanel>
  );
};

export default TaxAndSecurity;
