import React, { FC } from 'react';
import { Box, Divider, Drawer, IconButton, Typography } from '@mui/material';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

import { DirectorFormProps } from '@interface/company.interface';
import CompanyDirectorDetailView from '@component/CompanyDirectorDetailView';

const DirectorDrawer: FC<DirectorFormProps> = ({ open, onClose, director }) => (
  <Drawer anchor="bottom" open={open} onClose={onClose}>
    <Box width="100%" p={3} height="88vh" overflow="hidden">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Box>
          <Typography variant="h4" color="primary" fontWeight="bold">
            Director
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            {director?.fullName}
          </Typography>
        </Box>

        <IconButton onClick={onClose} color="warning">
          <HighlightOffRoundedIcon />
        </IconButton>
      </Box>

      <Divider />

      <Box height="80%" overflow="auto" pb={20}>
        <CompanyDirectorDetailView {...director} />
      </Box>
    </Box>
  </Drawer>
);

export default DirectorDrawer;
